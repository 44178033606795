import React from 'react'

export default () => {

  return (
    <div>
      <h3>Page not found</h3>
        <p>Oops! The page you are looking for has been removed or relocated.</p>
    </div>
  )
}
